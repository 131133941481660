import { onBeforeMount, watch } from 'vue'
import { staticI18n, useI18n } from '@/utils/i18n'

export const useTitle = (key: string) => {
  const i18n = useI18n()

  const setTitle = (val: string) => {
    document.title = val
  }

  onBeforeMount(() => {
    setTitle(staticI18n.global.t(key))
  })

  watch(i18n, () => {
    setTitle(staticI18n.global.t(key))
  })
}
