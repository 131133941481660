import { LocaleType, AVAILABLE_LOCALE } from '@/utils/i18n'

const KEY = 'cached'

export default {
  save(locale: LocaleType, key: string, value: any) {
    // create empty locale placeholders
    if (!sessionStorage.getItem(KEY)) {
      const localesData: Record<string, Record<string, any>> = {}

      AVAILABLE_LOCALE.forEach((locale) => {
        localesData[locale] = {}
      })

      sessionStorage.setItem(KEY, JSON.stringify(localesData))
    }

    const cached = JSON.parse(sessionStorage.getItem(KEY)!)
    cached[locale][key] = value
    cached[locale]['expiration_date'] = new Date().getTime() + 3600000 / 2 // cache expires after 30m

    sessionStorage.setItem(KEY, JSON.stringify(cached))
  },

  get(locale: LocaleType, key: string): null | any {
    const value = sessionStorage.getItem(KEY)
    if (!value) {
      return null
    }

    const data = JSON.parse(value)
    if (isExpired(data[locale]['expiration_date'])) {
      console.log('[!] Cache expired')
      sessionStorage.clear()
      return null
    }

    return data[locale][key]
  },
}

function isExpired(timestamp: number) {
  return timestamp < new Date().getTime()
}
