// import queryString from 'query-string'
import { defineStore } from 'pinia'
import { createI18n } from 'vue-i18n'
import { i18n } from '@/main'
import enLocaleData from '@/locales/en.json'
import plLocaleData from '@/locales/pl.json'

export const AVAILABLE_LOCALE = ['pl', 'en'] as const
export type LocaleType = (typeof AVAILABLE_LOCALE)[number]

// vue-i18n library used for static text translation
export const staticI18n = createI18n({
  locale: getDefaultLocale(),
  fallbackLocale: 'pl',
  globalInjection: true,
  messages: {
    en: enLocaleData,
    pl: plLocaleData,
  },
})

// pinia library used for dynamically-fetched text translation
export const useI18n = defineStore('locale', {
  state: () => {
    return {
      locale: getDefaultLocale(),
    }
  },
  actions: {
    setLocale(lang: LocaleType) {
      if (this.locale === lang) return

      console.log('[OK] Changing locale: ' + lang)

      this.locale = lang
      i18n.global.locale = lang

      localStorage.setItem('locale', lang)
      document.documentElement.lang = lang

      if (lang === 'pl') {
        history.replaceState({ lang: lang }, '', location.href.replace(location.search, ''))
      } else {
        history.replaceState({ lang: lang }, '', `?l=${lang}`)
      }
    },
  },
})

function parseQuery(queryString: string) {
  const query: Record<string, any> = {}
  const pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

// get user locale and check if it's available, default to english if
function getDefaultLocale() {
  const query = parseQuery(window.location.search)
  const queryLang = query.l

  if (queryLang && AVAILABLE_LOCALE.includes(queryLang)) {
    return queryLang as LocaleType
  }

  if (localStorage.getItem('locale')) {
    return localStorage.getItem('locale') as LocaleType
  }

  const lang = 'pl'

  localStorage.setItem('locale', lang)
  return lang as LocaleType
}
