import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { staticI18n } from '@/utils/i18n'

const i18n = staticI18n

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: i18n.global.t('pages.home.title'),
    },
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/MenuView.vue'),
    meta: {
      title: i18n.global.t('pages.menu.title'),
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            el: to.hash,
            behavior: 'smooth',
          })
        }
        resolve(savedPosition ? savedPosition : { top: 0 })
      }, 100)
    })
  },
})

router.beforeEach((toRoute, fromRoute, next) => {
  document.title =
    toRoute.meta && toRoute.meta.title ? (toRoute.meta.title as string) : '• Highlander Pizza'
  next()
})

export default router
