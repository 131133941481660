import cache from '@/utils/cache'
import { createClient } from 'contentful'
import { useI18n } from '@/utils/i18n'
import { Ref } from 'vue'

export const client = createClient({
  space: 'lxl5pcd862q6',
  accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN!,
})

export const getContent = (entries: Record<string, any>, key?: string): Record<string, any>[] => {
  return key ? entries.value.items[0].fields[key] : entries.value.items[0].fields
}

export const setEntries = async (entriesRef: Ref<any>, contentType: string, cacheKey: string) => {
  const i18n = useI18n()
  const cached = cache.get(i18n.locale, cacheKey)
  let entries = cached

  if (!cached) {
    entries = await client.getEntries({
      content_type: contentType,
      locale: i18n.locale,
      include: 10,
    })
    cache.save(i18n.locale, cacheKey, entries)
  }

  entriesRef.value = cache.get(i18n.locale, cacheKey)
}
