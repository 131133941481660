import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { staticI18n } from '@/utils/i18n'
import { ObserveVisibility } from 'vue-observe-visibility'

export const i18n = staticI18n

document.addEventListener('click', () => document.body.classList.remove('tabbing'))
document.addEventListener('keyup', (e) => {
  if (e.key === 'Tab') document.body.classList.add('tabbing')
})

const app = createApp(App)
  .directive('observe-visibility', ObserveVisibility)
  .use(createPinia())
  .use(staticI18n)
  .use(router)

document.addEventListener('DOMContentLoaded', () => {
  app.mount('#app')
})
