export const scrollToCenter = (targetId: string, margin = 0, disableCenter = false) => {
  const target = document.querySelector(targetId)
  if (!target) return

  const targetTop = target.getBoundingClientRect().top + document.documentElement.scrollTop

  if (target.clientHeight < window.innerHeight && !disableCenter) {
    const heightDiff = window.innerHeight - target.clientHeight
    window.scrollTo(0, targetTop - heightDiff / 2 - margin)
    return
  }

  window.scrollTo(0, targetTop - margin)
}
