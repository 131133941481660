import ArrowIcon from './ArrowIcon.vue'
import CloseIcon from './CloseIcon.vue'
import DownloadIcon from './DownloadIcon.vue'
import Logo from './Logo.vue'
import PhoneIcon from './PhoneIcon.vue'
import MailIcon from './MailIcon.vue'
import PizzaDrawingIcon from './PizzaDrawingIcon.vue'
import PizzaSliceIcon from './PizzaSliceIcon.vue'
import FacebookIcon from './FacebookIcon.vue'
import InstagramIcon from './InstagramIcon.vue'
import MapMarkerIcon from './MapMarkerIcon.vue'
import Mountain from './Mountain.vue'

export {
  ArrowIcon,
  CloseIcon,
  DownloadIcon,
  Logo,
  MailIcon,
  PhoneIcon,
  PizzaDrawingIcon,
  PizzaSliceIcon,
  FacebookIcon,
  InstagramIcon,
  MapMarkerIcon,
  Mountain,
}
