import { Ref, watch, onBeforeMount } from 'vue'
import { setEntries } from '@/utils/contentful'
import { useI18n } from './i18n'

export const componentSetup = (entries: Ref<any>, contentType: string, cacheKey?: string) => {
  cacheKey = cacheKey || contentType

  const i18n = useI18n()
  const _getEntries = () => setEntries(entries, contentType, cacheKey!)

  onBeforeMount(_getEntries) as any
  watch(i18n, _getEntries)
}
