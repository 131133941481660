import styles from '@/styles/modules/_media.module.scss'

import { onUnmounted, ref } from 'vue'

type MediaType = {
  desktop: boolean
  tablet: boolean
  mobile: boolean
  height: number
  width: number
}

export default function useMedia() {
  const desktop = parseInt(styles.desktop)
  const tablet = parseInt(styles.tablet)

  const getMedia = () =>
    (() => {
      const width = window.innerWidth
      const height = window.innerHeight

      return {
        desktop: width >= desktop,
        tablet: width < desktop && width >= tablet,
        mobile: width < tablet,
        height,
        width,
      }
    })()
  const setMedia = () => (media.value = getMedia())

  const media = ref<MediaType>(getMedia())

  window.addEventListener('resize', setMedia)
  onUnmounted(() => {
    window.removeEventListener('resize', setMedia)
  })

  return media
}
